import { toast } from 'react-hot-toast';
import axios from '../../helpers/axios';
import { cashConstants } from './constants';

const getAllCashes = (payload) => {
  return async (dispatch) => {
    //dispatch a get product request
    dispatch({ type: cashConstants.GET_ALL_CASHES_REQUEST });

    axios
      .get(`/cashes/year/${payload.year}`)
      .then((response) => {
        const { cashes } = response.data;
        dispatch({
          type: cashConstants.GET_ALL_CASHES_SUCCESS,
          payload: { cashes: cashes },
        });
      })
      .catch((error) => {
        dispatch({
          type: cashConstants.GET_ALL_CASHES_FAILURE,
          payload: { error: error },
        });
      });
  };
};

export const addCash = (payload, year) => {
  return async (dispatch) => {
    dispatch({ type: cashConstants.ADD_NEW_CASH_REQUEST });

    axios
      .post('cashes', payload)
      .then((response) => {
        dispatch({
          type: cashConstants.ADD_NEW_CASH_SUCCESS,
          payload: { cash: response.data.cash },
        });
        dispatch(getAllCashes({ year: year }));
        toast.success('New cash record created!');
      })
      .catch((error) => {
        dispatch({
          type: cashConstants.ADD_NEW_CASH_FAILURE,
          payload: { error: error },
        });
        toast.error('Cash record creation failed! Please try later');
      });
  };
};

export const updateCash = (payload, year) => {
  return async (dispatch) => {
    dispatch({ type: cashConstants.UPDATE_CASH_REQUEST });
    axios
      .put(`/cashes/${payload._id}`, payload)
      .then(() => {
        dispatch({ type: cashConstants.UPDATE_CASH_SUCCESS });
        dispatch(getAllCashes({ year: year }));
        toast.success('Cash record updated successfully!');
      })
      .catch((error) => {
        dispatch({
          type: cashConstants.UPDATE_CASH_FAILURE,
          payload: { error },
        });
        toast.error('Cash record update failed! Please try later');
      });
  };
};

export const deleteCash = (payload, year) => {
  return async (dispatch) => {
    dispatch({ type: cashConstants.DELETE_CASH_REQUEST });

    axios
      .delete(`/cashes/${payload._id}`)
      .then(() => {
        dispatch({ type: cashConstants.DELETE_CASH_SUCCESS });
        dispatch(getAllCashes({ year: year }));
        toast.success('Cash record deleted successfully!');
      })
      .catch((error) => {
        dispatch({
          type: cashConstants.DELETE_CASH_FAILURE,
          payload: { error },
        });
        toast.error('Cash record deletion failed! Please try later');
      });
  };
};

export { getAllCashes };
